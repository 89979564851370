import axios from "axios";

// const url = "https://dev.clubeventcal.com/users/";
// const url = "http://localhost:3001/";
const apiUrl = process.env.REACT_APP_ENV_API_URL;

export class ApiClient {
  // constructor(action) {
  // this.token = getCookie("token");
  // this.uid = getCookie("uid");
  // this.action = action;
  // this.formData = new FormData();
  // this.formData.append("token", this.token);
  // this.formData.append("action", action);
  // this.formData.append("uid", this.uid);
  // }

  async apiCall(method, url, data) {
    try {
      try {
        return await axios({
          method,
          url,
          data,
        });
      } catch (error) {
        console.log(error + " ERROR");
        throw error;
      }
    } finally {
      // console.log(this.action + " complete");
    }
  }

  authCheck() {
    return this.apiCall("GET", `${apiUrl}/auth_check`, "")
  }

  getSettings() {
    return this.apiCall("GET", `${apiUrl}/settings`, "")
  }

  googleCal() {
    const formData = new FormData();
    return this.apiCall("GET", `${apiUrl}/calendars`, formData);
  }

  logOut() {
    return this.apiCall("POST", `${apiUrl}/auth_check`, "")
  }


  stravaClub() {
    const formData = new FormData();
    return this.apiCall("GET", `${apiUrl}/clubs`, formData);
  }

  saveSettings(body) {
    return this.apiCall("POST", `${apiUrl}/settings`, body)
  }
}
