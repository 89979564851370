import React from "react";
import { Container } from "react-bootstrap";

export default function TermsofUse() {
  return (
    <Container>
      <h3>Terms of Use</h3>
      <p>
        <strong>Last Updated:</strong> 29th February 2024
      </p>
      <p>
        These Terms of Use ("Terms") govern your access and use of the Club
        Event Cal web application ("Service") and its associated features and
        functionalities (collectively, the "Platform"). By accessing or using
        the Platform, you agree to be bound by these Terms. If you do not agree
        to all of these Terms, do not use the Platform.
      </p>

      <h4>1. Account and Eligibility</h4>
      <p>
        <strong>1.1</strong> You must be at least 13 years old to use the
        Platform. By using the Platform, you represent and warrant that you are
        at least 13 years old and meet all other eligibility requirements under
        these Terms.
      </p>
      <p>
        <strong>1.2</strong> You are responsible for creating and maintaining a
        secure account on the Platform. You are solely responsible for all
        activities that occur under your account.
      </p>

      <h4>2. Use of the Platform</h4>
      <p>
        <strong>2.1</strong> You may use the Platform only for lawful purposes
        and in accordance with these Terms.
      </p>
      <p>
        <strong>2.2</strong> You agree not to:
      </p>
      <ul>
        <li>
          Reverse engineer, decompile, disassemble, or modify the Platform or
          any part thereof.
        </li>
        <li>
          Use the Platform in a manner that could interfere with, disrupt, or
          disable the Platform or any other user's use of the Platform.
        </li>
        <li>Violate any applicable laws or regulations.</li>
        <li>
          Infringe on the intellectual property rights of any third party.
        </li>
        <li>
          Access or attempt to access the Platform by any means other than the
          interface provided by us.
        </li>
      </ul>

      <h4>3. Third-Party Services</h4>
      <p>
        <strong>3.1</strong> The Platform may integrate with third-party
        services, such as Strava and Google Calendar. You acknowledge and agree
        that your use of these services is subject to their respective terms and
        conditions.
      </p>
      <p>
        <strong>3.2</strong> We are not responsible for the availability,
        accuracy, or content of any third-party services.
      </p>

      <h4>4. Disclaimers</h4>
      <p>
        <strong>4.1</strong> The Platform is provided "as is" and without
        warranties of any kind, express or implied. We disclaim all warranties,
        including, but not limited to, warranties of merchantability, fitness
        for a particular purpose, and non-infringement.
      </p>
      <p>
        <strong>4.2</strong> We do not warrant that the Platform will be
        uninterrupted, error-free, or free of viruses or other harmful
        components.
      </p>
      <p>
        <strong>4.3</strong> You are solely responsible for any damage or loss
        that results from your use of the Platform.
      </p>

      <h4>5. Limitation of Liability</h4>
      <p>
        <strong>5.1</strong> To the fullest extent permitted by law, we shall
        not be liable for any direct, indirect, incidental, consequential,
        special, or exemplary damages arising out of or related to your use of
        the Platform.
      </p>

      <h4>6. Intellectual Property</h4>
      <p>
        <strong>6.1</strong> The Platform and all content and materials included
        therein, including text, graphics, logos, icons, images, videos, and
        software, are the property of Club Event Cal oits affiliates or its
        content suppliers and is protected by UK and international copyright,
        authors’ rights, trademark, and other intellectual property laws.
      </p>
      <p>
        <strong>6.2</strong> You may not use any content or materials from the
        Platform for any commercial purpose without our express written
        permission.
      </p>

      <h4>7. Termination</h4>
      <p>
        <strong>7.1</strong> We may terminate your access to the Platform for
        any reason, at any time, and without notice to you.
      </p>
      <p>
        <strong>7.2</strong> You may terminate your use of the Platform at any
        time.
      </p>

      <h4>8. Governing Law and Dispute Resolution</h4>
      <p>
        <strong>8.1</strong> These Terms shall be governed by and construed in
        accordance with the laws of England and Wales, without regard to its
        conflict of law provisions.
      </p>
      <p>
        <strong>8.2</strong> Any dispute arising out of or relating to these
        Terms will be submitted to the exclusive jurisdiction of the courts of
        England and Wales.
      </p>

      <h4>9. Entire Agreement</h4>
      <p>
        <strong>9.1</strong> These Terms constitute the entire agreement between
        you and us with respect to the subject matter hereof and supersede all
        prior or contemporaneous communications, representations, or agreements,
        whether oral or written.
      </p>

      <h4>10. Amendment</h4>
      <p>
        <strong>10.1</strong> We may amend these Terms at any time by posting
        the amended terms on the Platform. Your continued use of the Platform
        following the posting of amended Terms constitutes your acceptance of
        the amended Terms.
      </p>

      <h4>11. Contact Us</h4>
      <p>
        <strong>11.1</strong> If you have any questions about these Terms,
        please contact us at:{" "}
        <a href="mailto:support@clubeventcal.com">support@clubeventcal.com</a>
      </p>
    </Container>
  );
}
