/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import SiteRoutes from "./components/SiteRoutes";
import { ApiClient } from "./ApiClient";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

function App() {
  const apiClient = new ApiClient();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  console.log(isAuthenticated);

  useEffect(() => {
    const checkCookie = async () => {
      const response = await apiClient.authCheck();
      console.log(response)
      const data = response.data;
      setIsAuthenticated(data.isAuthenticated);
    };
    checkCookie();
  }, []);

  const handleLogout = async () => {
    await apiClient.logOut();
    setIsAuthenticated(false);
    window.location.href = "/";
  };

  return (
    <>
      <NavBar isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
      <SiteRoutes />
      <Footer />
    </>
  );
}

export default App;
