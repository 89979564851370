import { NavLink } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";

function Footer() {
  const smallTextStyle = { fontSize: "0.85rem" };

  return (
    <>
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-between">
            <Navbar.Text className="text-muted">ClubEventCal ©2023</Navbar.Text>
            <div className="d-flex justify-content-center align-items-center">
              <Navbar.Text style={smallTextStyle}>
                <NavLink
                  as={Link}
                  to="/privacy"
                  className="text-white text-decoration-none me-4"
                >
                  Privacy Policy
                </NavLink>
              </Navbar.Text>
              <Navbar.Text style={smallTextStyle}>
                <NavLink
                  as={Link}
                  to="/terms"
                  className="text-white text-decoration-none"
                >
                  Terms of Use
                </NavLink>
              </Navbar.Text>
            </div>
            <Navbar.Brand href="/">
              <img
                alt=""
                src="/w_powered_strava.png"
                height="40"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Footer;
