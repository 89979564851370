import React from "react";
import { Container } from "react-bootstrap";

export default function Support() {
  return (
    <Container>
      <h3>Support</h3>
      <p>Welcome to the Club Event Cal Support Hub!</p>
      <p>
        At Club Event Cal, we're dedicated to enhancing your fitness journey by
        ensuring a seamless connection between your Strava Club Events and
        Google Calendar. We understand the importance of staying organized and
        connected, so our support hub is designed to provide you with all the
        assistance you need to navigate our platform effortlessly.
      </p>
      <h4>Frequently Asked Questions (FAQs):</h4>
      <ul>
        <li>How do I sync my Strava Club Events with my Google Calendar?</li>
        <ul>
          <li>
            Follow our easy step-by-step guide to start syncing your events
            effortlessly.
          </li>
        </ul>
        <li>Can I filter which events to import?</li>
        <ul>
          <li>
            Unfortunatley not. Our service will iterate throught all the club
            events and create a calendar entry for each one.
          </li>
        </ul>
        <li>What if an event doesn't show up on my calendar?</li>
        <ul>
          <li>
            It can take some time for events to sync, new events can take 12
            hours to sync.
          </li>
        </ul>
      </ul>

      <h4>Contact Us:</h4>
      <p>
        <strong>We're Here to Help!</strong>
      </p>

      <p>
        Can't find the answer you're looking for? Our dedicated team is ready to
        assist you with any queries or feedback.
      </p>

      <p>
        Email:{" "}
        <a href="mailto:support@clubeventcal.com">support@clubeventcal.com</a>
      </p>
    </Container>
  );
}
