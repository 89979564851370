import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AboutUs from "./about_us/AboutUs";
import GoogleAuthFail from "./google_authfail/GoogleAuthFail";
import StravaAuthFail from "./strava_authfail/StravaAuthFail";
import Settings from "./settings/Settings";
import NotFound from "./NotFound";
import HomePage from "./HomePage";
import Privacy from "./privacy/Privacy";
import Terms from "./terms/Terms";
import Support from "./support/Support";

export default function SiteRoutes() {
  const location = useLocation();
  return (
    <>
      <Routes location={location} key={location.pathname}>
        <Route path="about_us" element={<AboutUs />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="terms" element={<Terms />} />
        <Route path="support" element={<Support />} />

        <Route path="google_authfail" element={<GoogleAuthFail />} />

        <Route path="strava_authfail" element={<StravaAuthFail />} />

        <Route path="settings" element={<Settings />} />

        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}
