import React from "react";
import { Container } from "react-bootstrap";

export default function AboutUs() {
  return (
    <Container>
      <h3>Privacy Policy</h3>
      <p>
        <strong>Effective Date:</strong> 29th February 2024
      </p>

      <h4>Introduction</h4>
      <p>
        Club Event Cal ("we" or "us") is committed to protecting your privacy
        and the security of your personal information. This Privacy Policy
        explains how we collect, use, disclose, and store your personal
        information when you use our web application, Club Event Cal ("App").
      </p>

      <h4>1. Information We Collect</h4>
      <p>We collect the following information when you use the App:</p>
      <p>
        <strong>Personal Information:</strong>
      </p>
      <ul>
        <li>
          When you create an account, you may provide us with certain personal
          information, such as your name, email address, Strava ID, and Strava
          Club ID.
        </li>
        <li>
          Google Calendar ID: We may collect your Google Calendar ID if you
          choose to connect your Google Calendar to the App to synchronize
          events.
        </li>
      </ul>
      <p>
        <strong>Non-Personal Information:</strong>
      </p>
      <ul>
        <li>
          We may collect non-personal information about your use of the App,
          such as your device type, operating system, browsing activity, and
          usage statistics.
        </li>
      </ul>

      <h4>2. How We Use Your Information</h4>
      <p>We use the information we collect for the following purposes:</p>
      <ul>
        <li>To provide and operate the App.</li>
        <li>To create and manage your account.</li>
        <li>
          To send you important information about the App, such as updates,
          notifications, and security alerts.
        </li>
        <li>To improve the App and our services.</li>
        <li>To comply with legal and regulatory requirements.</li>
      </ul>

      <h4>3. Sharing Your Information</h4>
      <p>
        We may share your information with the following third-party service
        providers who help us operate the App:
      </p>
      <ul>
        <li>Strava: To connect your Strava account and import events.</li>
        <li>
          Google Calendar: To integrate with your Google Calendar and add
          events.
        </li>
      </ul>
      <p>
        We require all third-party service providers to comply with strict data
        privacy regulations and only process your information for the purposes
        described in this Privacy Policy.
      </p>
      <p>
        We will not share your personal information with any other third party
        without your consent, except as required by law.
      </p>

      <h4>4. Data Retention</h4>
      <p>
        We will retain your personal information for as long as your account is
        active or as needed to provide you with the services you have requested.
        We will also retain your information for as long as necessary to comply
        with legal and regulatory requirements.
      </p>

      <h4>5. Your Rights</h4>
      <p>
        Under the UK General Data Protection Regulation (UK GDPR), you have
        certain rights regarding your personal information:
      </p>
      <ul>
        <li>
          The right to access your personal information: You have the right to
          request a copy of the information we hold about you.
        </li>
        <li>
          The right to rectification: You have the right to request that we
          correct any inaccurate or incomplete information we hold about you.
        </li>
        <li>
          The right to erasure: You have the right to request that we erase your
          personal information.
        </li>
        <li>
          The right to restrict processing: You have the right to request that
          we restrict the way we process your personal information.
        </li>
        <li>
          The right to data portability: You have the right to request that we
          transfer your personal information to another organization.
        </li>
      </ul>

      <h4>6. Security</h4>
      <p>
        We take reasonable steps to protect your personal information from
        unauthorized access, disclosure, alteration, or destruction. However, no
        security measures are perfect, and we cannot guarantee the complete
        security of your information.
      </p>

      <h4>7. Children's Privacy</h4>
      <p>
        Our App is not intended for children under the age of 13. We do not
        knowingly collect personal information from children under 13. If you
        are a parent or guardian and you believe your child has provided us with
        personal information, please contact us.
      </p>

      <h4>8. Changes to this Privacy Policy</h4>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on the App.
      </p>

      <h4>9. Contact Us</h4>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at{" "}
        <a href="mailto:support@clubeventcal.com">support@clubeventcal.com</a>.
      </p>
    </Container>
  );
}
