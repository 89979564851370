import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Button, Table } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ApiClient } from "../../ApiClient";
import Form from "react-bootstrap/Form";

export default function Wizard({ stravaClubList, calendarList }) {
  const [progress, setProgress] = useState(0);
  const [cal, setCal] = useState("loading...");
  const [club, setClub] = useState("loading...");
  const [pageNo, setPageNo] = useState(1);
  const apiClient = new ApiClient();
  const totalPages = 3;

  const nextPage = () => {
    pageNo >= totalPages ? goToFinish() : increasePageNo();
    // pageNo = 1 ? $(`[name=frmbtn]`).addClass("hidden") : null;
  };

  const goToPrev = () => {
    pageNo <= 1 ? goToStart() : decreasePageNo();
  };

  const goToFinish = () => {
    alert("Settings have been saved, Thanks!");
    //Post settings to db
  };

  const goToStart = () => {
    $(`#page${pageNo}`).addClass("hidden");
    $(`[name=backbtn]`).addClass("hidden");
    setPageNo(0);
  };

  const increasePageNo = () => {
    $(`#page${pageNo}`).addClass("hidden");
    $(`#page${pageNo + 1}`).removeClass("hidden");
    setPageNo(pageNo + 1);
  };

  const decreasePageNo = () => {
    $(`#page${pageNo}`).addClass("hidden");
    $(`#page${pageNo - 1}`).removeClass("hidden");
    setPageNo(pageNo - 1);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const body = JSON.stringify({
        calendar_id: cal.id,
        club_id: club.id,
        calendar_name: cal.name,
        club_name: club.name,
      })
      console.log("Save Settings Request Body: ", body);
      const res = await apiClient.saveSettings(body)
      if (res.status === 200) {
        window.location.reload();
        alert("Settings have been saved, Thanks!");
      }
    } catch (err) {
      console.log(err);
      alert("Some error occurred");
    }
  };

  const handleCalChange = (e, name) => {
    console.log("cal_id", e.target.value);
    console.log("cal_name", name);
    setCal({ id: e.target.value, name: name });
  };

  const handleClubChange = (e, name) => {
    console.log("club_id", e.target.value);
    console.log("club_name", name);
    setClub({ id: e.target.value, name: name });
  };

  useEffect(() => {
    // if (pageNo >= 1) {
    setProgress((pageNo / totalPages) * 100);
    console.log("progress", progress);
    console.log("pageNo", pageNo);
    // }
  }, [pageNo, progress]);

  return (
    <>
      <Form id="wizard-form" onSubmit={(e) => submitHandler(e)}>
        <Button
          className="mb-1 hidden"
          id="back-button"
          name="backbtn"
          variant="primary"
          onClick={goToPrev}
          style={{ width: "100px" }}
        >
          Back
        </Button>
        <Button
          className="mb-1"
          id="next-button"
          name="nextbtn"
          variant="primary"
          onClick={nextPage}
          style={{ width: "100px" }}
        >
          Next
        </Button>
        {progress >= 1 && (
          <ProgressBar
            label={`${Math.trunc(progress)}%`}
            animated
            now={progress}
          />
        )}
        <div id="page1">
          <Form.Label className="question-text">
            Please select the google calendar you'd like to use below :-
          </Form.Label>
          {calendarList.map((data, i) => (
            <div key={i}>
              {/* {console.log(data)} */}
              <Form.Check
                type="radio"
                name="calendar-check"
                label={data.name}
                value={data.id}
                onChange={(e) => {
                  handleCalChange(e, data.name);
                }}
              />
            </div>
          ))}
          {/* <Button
            className="mb-1 "
            id="refresh-calendars"
            variant="primary"
            onClick={refreshCalendars}
            style={{ width: "100px" }}
          >
            Refresh Calendars List
          </Button> */}
        </div>
        <div id="page2" className="hidden">
          <Form.Label className="question-text">
            Please select the Strava club you'd like to connect to below :-
          </Form.Label>
          {stravaClubList.map((data, i) => (
            <div key={i}>
              <Form.Check
                type="radio"
                name="strava-club"
                label={data.name}
                value={data.id}
                onChange={(e) => {
                  handleClubChange(e, data.name);
                }}
              />
            </div>
          ))}
          {/* <Button
            className="mb-1 "
            id="refresh-strava-club"
            variant="primary"
            onClick={refreshStravaClub}
            style={{ width: "100px" }}
          >
            Refresh Calendars List
          </Button> */}
        </div>
        <div id="page3" className="hidden">
          <Form.Label className="question-text">
            Confirm your choices below, or press back to change them:-
          </Form.Label>
          <Table style={{ width: "60%" }}>
            <thead>
              <th>Google Calendar</th>
              <th>Strava Club</th>
            </thead>
            <tbody>
              <td>{cal.name}</td>
              <td>{club.name}</td>
            </tbody>
          </Table>
          <Button type="submit">Confirm</Button>
        </div>
      </Form>
    </>
  );
}
