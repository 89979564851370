import { Navbar, Nav, NavLink, Stack } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import config from "../Config";

function NavBar({ isAuthenticated, handleLogout }) {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand href="/">
            <Stack direction="horizontal" gap={3}>
              <img
                alt=""
                src="/cec-logo-3.png"
                width="40"
                height="40"
                className="d-inline-block align-top"
              />
              <div className="branding">
                <h1>
                  <span
                    style={{ color: "rgb(231,235,239)", "font-size": "2rem" }}
                  >
                    C
                  </span>
                  {/* <span style={{ color: "rgb(110,110,0)", 'font-size': "3rem" }}>C</span> */}
                  <span style={{ color: "rgb(231,235,239)" }}>LUB</span>
                  {/* <span style={{ color: "rgb(255,113,0)", 'font-size': "3rem"}}>e</span> */}
                  <span
                    style={{ color: "rgb(198,200,205)", "font-size": "2rem" }}
                  >
                    E
                  </span>
                  <span style={{ color: "rgb(198,200,205)" }}>VENT</span>
                  {/* <span style={{ color: "rgb(110,110,0)", 'font-size': "3rem" }}>C</span> */}
                  <span
                    style={{ color: "rgb(231,235,239)", "font-size": "2rem" }}
                  >
                    C
                  </span>
                  <span style={{ color: "rgb(231,235,239)" }}>AL</span>
                </h1>
              </div>
            </Stack>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            className="justify-content-end"
            id="responsive-navbar-nav"
          >
            <Nav>
              <NavLink as={Link} to="about_us">
                About Us
              </NavLink>
              <NavLink as={Link} to="support">
                Support
              </NavLink>
              {isAuthenticated ? (
                <NavLink as={Link} to="settings">
                  Settings
                </NavLink>
              ) : null}
              {isAuthenticated ? (
                <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
              ) : (
                <Nav.Link href={`${config.apiUrl}/authorize?platform=strava`}>
                  Login
                </Nav.Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
