/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { ApiClient } from "../../ApiClient";
import { Button, Container } from "react-bootstrap";
import Modal from "react-modal";
import Wizard from "../wizard/Wizard";
import config from "../../Config";

export default function Settings() {
  Modal.setAppElement("#root");
  const [modalIsOpen, setIsOpen] = useState(false);
  const apiClient = new ApiClient();
  const [userSettings, setUserSettings] = useState({
    'calendar_name': { 'S': 'Collecting...' },
    'club_name': { 'S': 'Collecting...' },
    'first_name': { 'S': 'Collecting...' },
    'last_name': { 'S': 'Collecting...' }
  });
  const [calendarList, setCalendarList] = useState([
    {
      id: 123123,
      name: "the test calendar",
    },
  ]);
  const [stravaClubList, setStravaClubList] = useState([
    {
      id: 2213123,
      name: "the test club",
    },
  ]);

  useEffect(() => {
    apiClient.getSettings().then((settings) => {
      console.log("User settings result", settings);
      setUserSettings(settings.data);
    });
  }, []);

  const customStylesModal = {
    // content: {
    //   top: "50%",
    //   left: "50%",
    //   right: "auto",
    //   bottom: "auto",
    //   width: "50%",
    //   marginRight: "-50%",
    //   padding: "100px",
    //   transform: "translate(-50%, -50%)",
    // },
    // overlay: { zIndex: 1000 },
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    refreshStravaClub();
    refreshCalendars();
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const refreshCalendars = () => {
    apiClient.googleCal().then((result) => {
      console.log("googleCal result", result);
      setCalendarList(result.data);
    });
  };

  const refreshStravaClub = () => {
    apiClient.stravaClub().then((result) => {
      console.log("stravaClub result", result);
      setStravaClubList(result.data);
    });
  };

  return (
    <>
      <Container>
        <h2>⚙️ Settings</h2>
        <br />
        {userSettings.calendar_name && userSettings.club_name ? (
          <>
            {userSettings.calendar_name.S === "Collecting..." || userSettings.club_name.S === "Collecting..." ? (
              <p>Collecting settings...</p>
            ) : (
                <>
                  <h4>
                    Welcome, {userSettings.first_name.S} {userSettings.last_name.S} 👋
                  </h4>
                  <p>
                  Use this settings page to configure your Strava club events to sync with a Google Calendar. Run the setup wizard to select your 🗓️ calendar and 🚴 Strava club to keep your calendar up-to-date with the latest activities.
                  </p>

                  <h5>
                    Current Settings:
                  </h5>
                  <p>
                    Calendar: {userSettings.calendar_name.S}
                  </p>
                <p>Club: {userSettings.club_name.S}</p>
              </>
            )}
          </>
        ) : (
          <p>No Settings found, please run the setup wizard.</p>
        )}
        <br />
        <a href={`${config.apiUrl}/authorize?platform=google`}>
          <img
            alt=""
            src="/google.png"
            height="50"
            className="d-inline-block align-top"
          />
        </a>
        {/* <Button href={`${apiUrl}/authorize?platform=google`}>
          Google Auth
        </Button> */}
        <br />
        <br />
        <Button
          className="mb-1 "
          id="open-modal"
          variant="primary"
          onClick={openModal}
          style={{ width: "100px", backgroundColor: "rgb(255,113,0)" }}
        >
          Run setup wizard
        </Button>
        <br />
        <br />
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={openModal}
          style={customStylesModal}
          contentLabel="Wizard Modal"
          shouldCloseOnOverlayClick={false}
        >
          <Wizard calendarList={calendarList} stravaClubList={stravaClubList} />
          <br />
          <br />

          <Button
            className="mb-1 "
            id="close-modal"
            variant="danger"
            onClick={closeModal}
            style={{ width: "100px" }}
          >
            Close
          </Button>
        </Modal>
      </Container>
    </>
  );
}
