import React from "react";
import { Container } from "react-bootstrap";
import "../index.css";
import config from "../Config";

export default function HomePage() {
  //domain clubeventcal.com

  // useEffect(() => {
  //   //functions here run on page load
  //   googleCalList();
  // }, []);
  return (
    <>
      {" "}
      <div className="bg">
        <Container>
          <br />
          <h5>Empower Your Fitness Journey: Sync Strava Club Events with ClubEventCal!</h5>
          <p>Welcome to Club Event Cal, where we bridge the gap between your passion for fitness and the ease of staying organized. Our seamless integration allows you to import Strava Club Events directly into your Google Calendar, ensuring you never miss a workout or group activity again. Join the Club Event Cal community today and revolutionize the way you plan, track, and conquer your fitness goals!</p>
          <br />
          <a href={`${config.apiUrl}/authorize?platform=strava`}>
            <img
              alt=""
              src="/connect_strava.png"
              height="50"
              className="d-inline-block align-top"
            />
          </a>
          <br />
          {/* <br />
          <Link to="google_authfail">Google Authfail page</Link>
          <br />
          <Link to="strava_authfail">Strava Authfail page</Link> */}
        </Container>
      </div>
    </>
  );
}
